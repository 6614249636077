import { userStatusCodes, userTypesCodes } from '@shared/types'

export const filterUserStatusValues = Object.entries(userStatusCodes).map(
  ([key, value]) => ({ value: key, label: value })
)

export const filterUserTypesValues = Object.entries(userTypesCodes).map(
  ([key, value]) => ({ value: key, label: value })
)

export function formatUserTypeCode(code: number): string {
  return userTypesCodes[code]
}
