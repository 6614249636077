import { omit } from 'lodash'
import { forwardRef, MutableRefObject, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Grid, Typography } from '@mui/material'

import { AdminAuthRole, api, AuthRoleUser, User } from '@shared/api'
import {
  Checkbox,
  FieldGroupContainer,
  Loading,
  NoData,
} from '@shared/components'
import { useNotification } from '@shared/hooks'
import { toArrayFieldErrors } from '@shared/utils'

interface UserRolesProps {
  user: User
  isOnModal?: boolean
  onSuccess: () => void
  setIsSubmitting: (value: boolean) => void
  setHasRoles?: (value: boolean) => void
  guidingId?: string
}

export type UserRolesData = {
  [key: string]: boolean
}

const useStyles = tss.withName('UserRoles').create(({ theme }) => ({
  labelTitle: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: theme.palette['neutral-900'],
  },
  labelBody: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    color: theme.palette['neutral-500'],
  },
}))

export const UserRoles = forwardRef(
  (
    {
      user,
      isOnModal = false,
      onSuccess,
      setIsSubmitting,
      setHasRoles,
      guidingId,
    }: UserRolesProps,
    ref: MutableRefObject<HTMLFormElement>
  ) => {
    const { classes } = useStyles()
    const { t } = useTranslation()
    const { setNotification } = useNotification()

    const methods = useForm<UserRolesData>()
    const { setValue } = methods

    const [availableRoles, setAvailableRoles] = useState<AdminAuthRole[]>([])

    const [isLoading, setIsLoading] = useState(true)
    const [isAllSelected, setIsAllSelected] = useState(false)

    const [userAuthRoles, setUserAuthRoles] = useState<AuthRoleUser[]>([])

    const getRoles = async () => {
      const [authRoles, userAuthRoles] = await Promise.all([
        api.service('admin-auth-roles').find({
          query: {
            page: {
              size: 50,
            },
            filter: {
              user_type_code: user?.user_type_code,
            },
          },
        }),
        api.service('auth-role-users').find({
          query: {
            page: {
              size: 100,
            },
            filter: {
              user_id: user?.id,
            },
          },
        }),
      ])

      setAvailableRoles(authRoles)
      setUserAuthRoles(userAuthRoles)

      setHasRoles?.(authRoles.length > 0)

      userAuthRoles.forEach((userAuth) => {
        setValue(userAuth.auth_role_code.toString(), true)
      })

      setIsLoading(false)
    }

    useEffect(() => {
      if (!user) return
      getRoles()
    }, [user])

    const onSubmit = async (data: UserRolesData) => {
      try {
        setIsSubmitting(true)
        await Promise.all(
          availableRoles.map(async (role) => {
            const userAuthRole = userAuthRoles.find(
              (uar) => uar.auth_role_code === role.id
            )
            const isSelected = data[role.id]

            if (!isSelected && userAuthRole) {
              await api
                .service('auth-role-users')
                .remove(`${userAuthRole.code}`)
            }

            if (isSelected && !userAuthRole) {
              await api.service('auth-role-users').create({
                user_id: user?.id,
                auth_role_code: role.id,
              })
            }
          })
        )
        onSuccess()
      } catch (error) {
        const errors = toArrayFieldErrors(error)
        const messages = errors.map((error) => error.message)

        setNotification({
          type: 'error',
          label: messages.join(' | '),
        })
      } finally {
        setIsSubmitting(false)
      }
    }

    return (
      <FieldGroupContainer
        title={!isOnModal && t('user-management.user-roles')}
        titleTestID="user-roles-title"
      >
        {isLoading ? (
          <Loading
            style={{
              maxHeight: '300px',
            }}
          />
        ) : (
          <>
            {availableRoles.length === 0 ? (
              <NoData
                title={t('user-management.no-roles-available')}
                label={t('user-management.no-roles-available-description')}
              />
            ) : (
              <form ref={ref} onSubmit={methods.handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Checkbox
                      label={
                        <Box width="100%">
                          <Typography key={0} className={classes.labelTitle}>
                            {t('common.ag-grid.select-all')}
                          </Typography>
                        </Box>
                      }
                      onChange={() => {
                        const newValue = !isAllSelected
                        availableRoles.forEach(({ id }) => {
                          setValue(id.toString(), newValue)
                        })
                        setIsAllSelected(newValue)
                      }}
                      checked={isAllSelected}
                      guidingId={`${guidingId}-selectall`}
                    />
                  </Grid>
                  {availableRoles.map(({ id, title, description }, index) => (
                    <Grid item xs={isOnModal ? 12 : 6} key={index}>
                      <Controller
                        name={id.toString()}
                        control={methods.control}
                        render={({ field }) => (
                          <Checkbox
                            {...omit(field, 'ref')}
                            label={
                              <Box width="100%">
                                <Typography
                                  key={0}
                                  className={classes.labelTitle}
                                >
                                  {title}
                                </Typography>
                                <Typography
                                  key={1}
                                  className={classes.labelBody}
                                >
                                  {description}
                                </Typography>
                              </Box>
                            }
                            checked={!!field.value}
                            guidingId={`${guidingId}-${title.toLowerCase()}`}
                          />
                        )}
                      />
                    </Grid>
                  ))}
                </Grid>
              </form>
            )}
          </>
        )}
      </FieldGroupContainer>
    )
  }
)
