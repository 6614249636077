import { isUndefined, omitBy } from 'lodash'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AppBar, Box, Grid } from '@mui/material'

import { User } from '@shared/api'
import {
  AdvancedSettings,
  Button,
  ButtonBar,
  ButtonBarEnd,
  HasPermission,
  Stepper,
} from '@shared/components'
import { useAuthorization, useFtpPortalHubCommunication } from '@shared/hooks'
import { checkAllPermissions } from '@shared/utils'

import { LocationAccess } from '@/components/location-access/LocationAccess'
import { UserRoles } from '@/components/user-roles/UserRoles'

import { UserDetails } from './components/UserDetails'

const useStyles = tss.withName('UserManagementAdd').create(({ theme }) => ({
  container: {
    padding: '24px',
    backgroundColor: 'white',
    borderRadius: '6px',
    width: '100%',
  },
}))

export const UserManagementAdd: FC = () => {
  const { classes } = useStyles()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { userPermissionSet } = useAuthorization()

  const [activeStep, setActiveStep] = useState(0)
  const [user, setUser] = useState<User>()

  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const formUserDetailsRef = useRef<HTMLFormElement>(null)
  const formUserRolesRef = useRef<HTMLFormElement>(null)

  const steps = useMemo(() => {
    const allSteps = [
      {
        label: t('user-management.user-details'),
        privs: ['v2.users.post'],
      },
      {
        label: t('user-management.user-roles'),
        privs: [
          'v2admin.authroles.get',
          'v2.authroleusers.get',
          'v2.authroleusers.post',
        ],
      },
      {
        label: t('user-management.login-access'),
        privs: ['v2.users.put'],
        skip: !user?.api_only,
      },
      {
        label: t('user-management.location'),
        privs: [
          'v2.locationusers.get',
          'v2.locations.get',
          'v2.locationusers.post',
          'v2.locationusers.delete',
        ],
      },
    ]

    return allSteps
      .filter((step) => checkAllPermissions(userPermissionSet, ...step.privs))
      .map((step, index) => ({
        label: step.label,
        number: index,
        skip: !!step.skip,
      }))
  }, [userPermissionSet, t, user])

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      let nextStep = activeStep + 1
      while (steps[nextStep].skip) {
        nextStep++
      }
      if (nextStep < steps.length) {
        setActiveStep(steps[nextStep].number)
        return
      }
    }

    const portal = sessionStorage.getItem('portal')
    navigate(`/${portal}/user-management/${user?.id}/view`)
  }

  useEffect(() => {
    if (user) {
      setAppBarTitle(`${user.first_name || ''} ${user.last_name}`, undefined, [
        t('common.user-management'),
        t('common.add-user'),
      ])
    } else {
      setAppBarTitle(t('common.add-user'), undefined, [
        t('common.user-management'),
      ])
    }
  }, [user])

  const onSuccessUserDetails = (user: User) => {
    setUser(user)

    handleNext()

    setIsSubmitLoading(false)
  }

  const onSuccessRoles = () => {
    handleNext()

    setIsSubmitLoading(false)
  }

  const onContinue = () => {
    if (activeStep === 0) {
      formUserDetailsRef.current?.requestSubmit()
      return
    }
    if (activeStep === 1) {
      formUserRolesRef.current?.requestSubmit()
      return
    }
    handleNext()
  }

  const onPrevious = () => {
    let prevStep = activeStep - 1

    while (steps[prevStep].skip) {
      prevStep--
    }

    if (prevStep >= 0) {
      setActiveStep(steps[prevStep].number)
    }
  }

  return (
    <HasPermission allPermissions={['v2.users.post']}>
      <>
        <Grid container paddingBottom="80px">
          <Grid item xs={2}>
            <Stepper
              steps={steps}
              activeStep={activeStep}
              onChange={() => {}}
            />
          </Grid>

          <Grid item xs={10}>
            <Box className={classes.container}>
              {activeStep === 0 && (
                <UserDetails
                  ref={formUserDetailsRef}
                  onSuccess={onSuccessUserDetails}
                  setIsSubmitting={setIsSubmitLoading}
                  guidingId="usermanagement-add"
                />
              )}
              {activeStep === 1 && (
                <UserRoles
                  ref={formUserRolesRef}
                  user={user}
                  onSuccess={onSuccessRoles}
                  setIsSubmitting={setIsSubmitLoading}
                  guidingId="usermanagement-add"
                />
              )}
              {activeStep === 2 && (
                <AdvancedSettings
                  user={user}
                  setUser={setUser}
                  isNewUserProcess
                  guidingId="usermanagement-add"
                />
              )}
              {activeStep === 3 && (
                <LocationAccess user={user} guidingId="usermanagement-add" />
              )}
            </Box>
          </Grid>
        </Grid>

        <AppBar
          sx={{
            bottom: 0,
            top: 'auto',
            position: 'fixed',
            boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
          }}
        >
          <ButtonBar style={{ marginBottom: '0 !important' }}>
            <ButtonBarEnd>
              <Button
                key={0}
                type="button"
                label={t('common.cancel')}
                testId="cancel-button"
                color="secondary"
                onClick={() => {
                  const portal = sessionStorage.getItem('portal')
                  navigate(`/${portal}/user-management`)
                }}
                style={{
                  marginRight: '8px',
                }}
                guidingId="usermanagement-add-cancel"
              />
              {![0, 1].includes(activeStep) && (
                <Button
                  type="button"
                  label={t('common.back')}
                  testId="back-button"
                  color="secondary"
                  onClick={onPrevious}
                  style={{
                    marginRight: '8px',
                  }}
                  guidingId="usermanagement-add-back"
                />
              )}
              <Button
                key={1}
                type="button"
                label={
                  activeStep === steps.length - 1
                    ? t('common.done')
                    : t('common.continue')
                }
                testId="continue-button"
                onClick={onContinue}
                isLoading={isSubmitLoading}
                disabled={isSubmitLoading}
                guidingId="usermanagement-add-continue-done"
              />
            </ButtonBarEnd>
          </ButtonBar>
        </AppBar>
      </>
    </HasPermission>
  )
}
