import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { api, AuthRoleUser, User } from '@shared/api'
import { FieldGroupContainer, Loading, TagList } from '@shared/components'
import { useAuthorization } from '@shared/hooks'
import { UserStatusEnum } from '@shared/types'
import { checkAllPermissions } from '@shared/utils'

import { EditRolesModal } from '@/components/edit-roles-modal/EditRolesModal'

export function UserRoles({ user }: { user: User }) {
  const { t } = useTranslation()
  const { userPermissionSet } = useAuthorization()

  const [authRoles, setAuthRoles] = useState<AuthRoleUser[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditRolesModalOpen, setIsEditRolesModalOpen] = useState(false)

  const getRoles = async () => {
    setIsLoading(true)
    const [authRoles, userAuthRoles] = await Promise.all([
      api.service('admin-auth-roles').find({
        query: {
          page: {
            size: 100,
          },
          filter: {
            user_type_code: user.user_type_code,
          },
        },
      }),
      api.service('auth-role-users').find({
        query: {
          filter: {
            user_id: user.id,
          },
          page: {
            size: 100,
          },
        },
      }),
    ])

    const userRoles = authRoles.filter(({ id }) =>
      userAuthRoles.some(({ auth_role_code }) => auth_role_code === id)
    )

    setAuthRoles(userRoles)

    setIsLoading(false)
  }

  // On mount, use the user's auth_roles to set the initial state
  useEffect(() => {
    if (!user) return
    setAuthRoles(user.auth_roles || [])
  }, [user])

  const onSuccessEditRoles = () => {
    getRoles()
    setIsEditRolesModalOpen(false)
  }

  const isEditButtonVisible = checkAllPermissions(
    userPermissionSet,
    'v2admin.authroles.get',
    'v2.authroleusers.post',
    'v2.authroleusers.delete'
  )

  return (
    <>
      <EditRolesModal
        user={user}
        isOpen={isEditRolesModalOpen}
        onClose={() => setIsEditRolesModalOpen(false)}
        onSuccess={onSuccessEditRoles}
        guidingId="usermanagement-view"
      />

      <FieldGroupContainer
        title={t('user-management.user-roles')}
        buttonData={
          isEditButtonVisible
            ? {
                label: t('user-management.edit-roles'),
                onClick: () => setIsEditRolesModalOpen(true),
                color: 'secondary',
                testId: 'edit-roles-button',
                guidingId: 'usermanagement-view-editroles',
              }
            : undefined
        }
      >
        {isLoading ? (
          <Loading
            style={{
              maxHeight: '100px',
            }}
          />
        ) : (
          <TagList
            items={authRoles || []}
            nameSelector={(role) => role.title as string}
          />
        )}
      </FieldGroupContainer>
    </>
  )
}
