import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import Notification, {
  NotificationProps,
} from '../components/feedback/Notification/Notification'

export type NotificationData = Omit<NotificationProps, 'show'>

type NotificationContext = {
  setNotification: Dispatch<SetStateAction<NotificationData | null>>
}

const notificationContext = createContext<NotificationContext | null>(null)

export const NotificationProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [notification, _setNotification] = useState<NotificationData | null>(
    null
  )

  const [isNotificationOpen, setIsNotificationOpen] = useState(false)

  const setNotification = (notification: NotificationData) => {
    if (notification) {
      setIsNotificationOpen(true)
    }
    _setNotification(notification)
  }

  return (
    <notificationContext.Provider value={{ setNotification }}>
      <>
        <Notification
          label={notification?.label}
          show={isNotificationOpen}
          type={notification?.type}
          duration={notification?.duration}
          onClose={() => {
            setIsNotificationOpen(false)
            notification?.onClose?.()
          }}
        />
        {children}
      </>
    </notificationContext.Provider>
  )
}

export function useNotification(): NotificationContext {
  const context = useContext(notificationContext)

  if (!context) {
    throw new Error(
      `${useNotification.name} must be used within a ${NotificationProvider.name}`
    )
  }

  return context
}
