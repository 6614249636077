import { FC, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { User } from '@shared/api'
import { ActionModal, ButtonProps } from '@shared/components'

import { UserRoles } from '../user-roles/UserRoles'

interface EditRolesModalProps {
  user: User
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
  guidingId?: string
}

const useSyles = tss.withName('EditRolesModal').create(({ theme }) => ({
  container: {
    width: '500PX',
  },
}))

export const EditRolesModal: FC<EditRolesModalProps> = ({
  user,
  isOpen,
  onClose,
  onSuccess,
  guidingId,
}) => {
  const { t } = useTranslation()
  const { classes } = useSyles()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasAvailableRoles, setHasAvailableRoles] = useState(false)

  const formUserRolesRef = useRef<HTMLFormElement>(null)

  const onSubmit = () => {
    formUserRolesRef.current.requestSubmit()
  }

  const returnButtons = useMemo(() => {
    const buttons: [ButtonProps] = [
      {
        label: t('common.cancel'),
        onClick: onClose,
        guidingId: `${guidingId}-editroles-cancel`,
      },
    ]

    if (hasAvailableRoles) {
      buttons.push({
        label: t('common.done'),
        onClick: onSubmit,
        isLoading: isSubmitting,
        guidingId: `${guidingId}-editroles-done`,
      })
    }

    return buttons
  }, [hasAvailableRoles, isSubmitting])

  return (
    <ActionModal
      open={isOpen}
      onClose={onClose}
      title={t('user-management.edit-roles')}
      buttons={returnButtons}
      className={classes.container}
      guidingId={`${guidingId}-editroles`}
    >
      <UserRoles
        user={user}
        onSuccess={onSuccess}
        setIsSubmitting={setIsSubmitting}
        setHasRoles={setHasAvailableRoles}
        ref={formUserRolesRef}
        isOnModal
        guidingId={`${guidingId}-editroles`}
      />
    </ActionModal>
  )
}
