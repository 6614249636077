import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { User } from '@shared/api'
import { FieldGroupContainer } from '@shared/components'
import { UserStatusEnum } from '@shared/types'

export function Security({ user }: { user: User }) {
  const { t } = useTranslation()
  return (
    <FieldGroupContainer
      title={t('user-management.security')}
      buttonData={
        user.status_code === UserStatusEnum.PendingInvitation
          ? null
          : {
              label: t('common.password-reset'),
              onClick: () => {},
              color: 'secondary',
              guidingId: 'usermanagement-view-passwordreset',
            }
      }
    >
      <Typography>
        {t('user-management.user-will-be-required-to-set-their-own-password')}
      </Typography>
    </FieldGroupContainer>
  )
}
