import {
  MerchantAccount,
  ProductTransactionDetail,
  User,
  Location,
  Terminal,
} from '../../../api/src'
import {
  EbtTransactionType,
  TransactionType,
} from '../../../api/src/schemas/types'
import {
  PaymentMethod,
  ProcessMethod,
  SelectOption,
  PaymentMethodEbt,
} from '../../../types'
import { getUserPermissionsMap } from '../../permissions/permissions'
import { getValidTerminals } from '../get-valid-terminals/getValidTerminals'

const SALE_OPTION: SelectOption<TransactionType> = {
  label: 'sale',
  value: 'sale',
}

const AUTH_ONLY_OPTION: SelectOption<TransactionType> = {
  label: 'auth only',
  value: 'authonly',
}

const AVS_ONLY_OPTION: SelectOption<TransactionType> = {
  label: 'avs only',
  value: 'avsonly',
}

const REFUND_OPTION: SelectOption<TransactionType> = {
  label: 'refund',
  value: 'refund',
}

const FORCE_OPTION: SelectOption<TransactionType> = {
  label: 'force',
  value: 'force',
}

const CASH_SALE_OPTION: SelectOption<TransactionType> = {
  ...SALE_OPTION,
  value: 'cash.sale',
}

const CASH_REFUND_OPTION: SelectOption<TransactionType> = {
  ...REFUND_OPTION,
  value: 'cash.refund',
}

const COLLECT_DEBIT_OPTION: SelectOption<TransactionType> = {
  label: 'collect (debit)',
  value: 'debit',
}

const SEND_CREDIT_OPTION: SelectOption<TransactionType> = {
  label: 'send (credit)',
  value: 'credit',
}

const EBT_SALE_OPTION: SelectOption<TransactionType | EbtTransactionType> = {
  ...SALE_OPTION,
  value: 'ebt.sale',
}

const EBT_REFUND_OPTION: SelectOption<TransactionType | EbtTransactionType> = {
  ...REFUND_OPTION,
  value: 'ebt.refund',
}

const EBT_BALANCE_INQUIRY_OPTION: SelectOption<
  TransactionType | EbtTransactionType
> = {
  label: 'Balance Inquiry',
  value: 'ebt.balanceinquiry',
}

const EBT_VOUCHER_CLEAR_SALE_OPTION: SelectOption<
  TransactionType | EbtTransactionType
> = {
  label: 'Voucher Clear - Sale',
  value: 'ebt.voucherclearsale',
}

const EBT_VOUCHER_CLEAR_REFUND_OPTION: SelectOption<
  TransactionType | EbtTransactionType
> = {
  label: 'Voucher Clear - Refund',
  value: 'ebt.voucherclearrefund',
}

const ccTransactionTypesOptions: SelectOption<TransactionType>[] = [
  SALE_OPTION,
  AUTH_ONLY_OPTION,
  AVS_ONLY_OPTION,
  REFUND_OPTION,
  FORCE_OPTION,
]

const achTransactionTypesOptions: SelectOption<TransactionType>[] = [
  COLLECT_DEBIT_OPTION,
  SEND_CREDIT_OPTION,
]

const cashTransactionTypesOptions: SelectOption<TransactionType>[] = [
  CASH_SALE_OPTION,
  CASH_REFUND_OPTION,
]

const ebtTransactionTypesOptions: SelectOption<
  TransactionType | EbtTransactionType
>[] = [
  EBT_BALANCE_INQUIRY_OPTION,
  EBT_REFUND_OPTION,
  EBT_SALE_OPTION,
  EBT_VOUCHER_CLEAR_REFUND_OPTION,
  EBT_VOUCHER_CLEAR_SALE_OPTION,
]

const permissionPath = 'v2.transactions.post'

const filterAchTransactionTypes = (
  account: MerchantAccount | ProductTransactionDetail,
  transactionTypeOptions: SelectOption<TransactionType | EbtTransactionType>[]
): SelectOption<TransactionType | EbtTransactionType>[] => {
  return transactionTypeOptions.filter(
    (option) =>
      (option.value === COLLECT_DEBIT_OPTION.value &&
        account.ach_allow_debit) ||
      (option.value === SEND_CREDIT_OPTION.value && account.ach_allow_credit)
  )
}

const filterCcTransactionTypes = (
  account: MerchantAccount | ProductTransactionDetail,
  transactionTypeOptions: SelectOption<TransactionType | EbtTransactionType>[],
  processMethod?: ProcessMethod
): SelectOption<TransactionType | EbtTransactionType>[] => {
  return transactionTypeOptions.filter((option) => {
    const refundOptions: (TransactionType | EbtTransactionType)[] = [
      REFUND_OPTION.value,
    ]
    return (
      (!refundOptions.includes(option.value) || !!account.allow_blind_refund) &&
      !(option.value === FORCE_OPTION.value && processMethod === 'terminal')
    )
  })
}

const filterCashTransactionTypes = (
  account: MerchantAccount | ProductTransactionDetail,
  transactionTypeOptions: SelectOption<TransactionType | EbtTransactionType>[]
): SelectOption<TransactionType | EbtTransactionType>[] => {
  return transactionTypeOptions.filter((option) => {
    const refundOptions: (TransactionType | EbtTransactionType)[] = [
      CASH_REFUND_OPTION.value,
    ]
    return !refundOptions.includes(option.value) || !!account.allow_blind_refund
  })
}

const filterEbtTransactionTypes = (
  account: MerchantAccount | ProductTransactionDetail,
  transactionTypeOptions: SelectOption<TransactionType | EbtTransactionType>[],
  processMethod?: ProcessMethod,
  location?: Location,
  user?: User,
  locationTerminals?: Terminal[]
): SelectOption<TransactionType | EbtTransactionType>[] => {
  return transactionTypeOptions.filter((option) => {
    const foodStampOptions: (TransactionType | EbtTransactionType)[] = [
      EBT_VOUCHER_CLEAR_REFUND_OPTION.value,
      EBT_VOUCHER_CLEAR_SALE_OPTION.value,
    ]
    const terminalOptions: (TransactionType | EbtTransactionType)[] = [
      EBT_SALE_OPTION.value,
      EBT_REFUND_OPTION.value,
      EBT_BALANCE_INQUIRY_OPTION.value,
    ]
    if (account.allow_ebt_food_stamp) {
      if (foodStampOptions.includes(option.value)) {
        return option
      }
    }
    const validTerminals = getValidTerminals(
      location,
      account,
      user,
      locationTerminals
    )
    if (validTerminals?.length) {
      if (terminalOptions.includes(option.value)) {
        return option
      }
    }
  })
}

export const getTransactionTypesForAccount = (
  account: MerchantAccount | ProductTransactionDetail | undefined,
  user: User,
  processMethod?: ProcessMethod,
  location?: Location,
  locationTerminals?: Terminal[],
  paymentMethod?: PaymentMethod | PaymentMethodEbt
): SelectOption<TransactionType | EbtTransactionType>[] => {
  if (!account) return []
  const options: SelectOption<PaymentMethod | PaymentMethodEbt>[] = [
    { label: 'CC', value: 'cc' },
    { label: 'ACH', value: 'ach' },
    { label: 'Cash', value: 'cash' },
    { label: 'EBT', value: 'cc' },
  ]
  let option = null
  if (account.card_type_ebt && paymentMethod === 'ebt') {
    option = options[3]
  } else {
    option = options.filter(
      (option) => option.value === account.payment_method
    )[0]
  }

  const transactionTypeOptions = getTransactionTypesForPaymentMethod(option)

  let baseMethods: SelectOption<TransactionType | EbtTransactionType>[]
  switch (option.label) {
    case 'CC':
      baseMethods = filterCcTransactionTypes(
        account,
        transactionTypeOptions,
        processMethod
      )
      break

    case 'ACH':
      baseMethods = filterAchTransactionTypes(account, transactionTypeOptions)
      break

    case 'Cash':
      // available cash types only rely on logged in user privs. not any other merchantaccount properties
      baseMethods = filterCashTransactionTypes(account, transactionTypeOptions)
      break
    case 'EBT':
      baseMethods = filterEbtTransactionTypes(
        account,
        transactionTypeOptions,
        processMethod,
        location,
        user,
        locationTerminals
      )
      break

    default:
      baseMethods = []
  }

  // Uses a map to avoid having to iterate through permissions list for each permission.
  const permissions = getUserPermissionsMap(user)

  return baseMethods.filter((method) => {
    if (method?.value?.includes('ebt')) {
      return !!permissions[`${permissionPath}.${method.value.split('.')[1]}`]
    }
    return !!permissions[`${permissionPath}.${method.value}`]
  })
}

export const getTransactionTypesForPaymentMethod = (
  option: SelectOption<PaymentMethod | PaymentMethodEbt>
): SelectOption<TransactionType | EbtTransactionType>[] => {
  switch (option.label) {
    case 'CC':
      return ccTransactionTypesOptions

    case 'ACH':
      return achTransactionTypesOptions

    case 'Cash':
      return cashTransactionTypesOptions

    case 'EBT':
      return ebtTransactionTypesOptions

    default:
      return []
  }
}
