import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { FieldGroupContainer } from '@shared/components'

export function Invitation() {
  const { t } = useTranslation()

  return (
    <FieldGroupContainer title={t('user-management.invitation')}>
      <Typography>
        {t('user-management.user-invitation-is-still-pending')}
      </Typography>
    </FieldGroupContainer>
  )
}
