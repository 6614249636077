import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { Grid } from '@mui/material'

import {
  Input,
  InputPhone,
  SelectOption,
  FieldGroupRow,
  SelectComponent as Select,
} from '@shared/components'
import { userTypesCodes, UserTypeType } from '@shared/types'
import { timezoneOptions } from '@shared/utils'

interface UserDetailsFormProps {
  guidingId?: string
}

export const buildUserDetailsSchema = (t: (text: string) => string) =>
  yup.object().shape({
    first_name: yup.string(),
    last_name: yup
      .string()
      .required(t('common.validations.last-name-required')),
    email: yup
      .string()
      .email()
      .required(t('common.validations.email-required')),
    cell_phone: yup.string(),
    user_type_code: yup.number().required(t('common.validations.user-type')),
    tz: yup.string().required(t('common.validations.timezone')),
  })

export const UserDetailsForm: FC<UserDetailsFormProps> = ({ guidingId }) => {
  const { t } = useTranslation()
  const userSchema = buildUserDetailsSchema(t)
  const methods = useFormContext<yup.InferType<typeof userSchema>>()

  const {
    control,
    formState: { errors },
  } = methods

  const getUserTypeOptions = () => {
    const options: SelectOption<UserTypeType>[] = []

    for (const [key, value] of Object.entries(userTypesCodes)) {
      options.push({ value: key, label: value })
    }

    return options
  }

  return (
    <>
      <FieldGroupRow spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="first_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="first-name-input"
                label={t('common.name-first')}
                placeholder={t('common.name-first-placeholder')}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
                guidingId={`${guidingId}-firstname`}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="last_name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                testId="last-name-input"
                label={t('common.name-last')}
                placeholder={t('common.name-last-placeholder')}
                required
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
                guidingId={`${guidingId}-lastname`}
              />
            )}
          />
        </Grid>
      </FieldGroupRow>

      <FieldGroupRow spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                label={t('common.email')}
                testId="email-input"
                placeholder={t('common.email-user-placeholder')}
                required
                error={!!errors.email}
                helperText={errors.email?.message}
                guidingId={`${guidingId}-email`}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="cell_phone"
            control={control}
            render={({ field }) => (
              <InputPhone
                {...field}
                label={t('common.phone-number')}
                testId="phone-number-input"
                error={!!errors.cell_phone}
                helperText={errors.cell_phone?.message}
                guidingId={`${guidingId}-cellphone`}
              />
            )}
          />
        </Grid>
      </FieldGroupRow>

      <FieldGroupRow spacing={2}>
        <Grid item xs={12} md={6}>
          <Controller
            name="user_type_code"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label={t('common.user-type')}
                options={getUserTypeOptions()}
                placeholder={t('common.select-user-type')}
                required
                style={{
                  width: '100%',
                  maxWidth: 'unset',
                  border: 'unset',
                  height: '44px',
                }}
                testId="user-type-input"
                error={!!errors.user_type_code}
                helperText={errors.user_type_code?.message}
                guidingId={`${guidingId}-usertypecode`}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="tz"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                label={t('common.timezone')}
                placeholder={t('common.select-timezone')}
                options={timezoneOptions}
                required
                style={{
                  width: '100%',
                  maxWidth: 'unset',
                  border: 'unset',
                  height: '44px',
                }}
                testId="timezone-input"
                error={!!errors.tz}
                helperText={errors.tz?.message}
                guidingId={`${guidingId}-timezone`}
              />
            )}
          />
        </Grid>
      </FieldGroupRow>
    </>
  )
}
