import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { api, User } from '@shared/api/src'
import {
  UserStatus,
  ThreeDotMenu,
  UserNotAllowed,
  HasPermission,
} from '@shared/components'
import {
  useAuthorization,
  useFilterModel,
  useFtpPortalHubCommunication,
  useLocations,
  useNotification,
} from '@shared/hooks'
import { EnumServiceName, UserStatusEnum, userTypesCodes } from '@shared/types'
import { checkPermission, DataSource } from '@shared/utils'

import { UserActivationModal } from '@/components/user-activation-modal/UserActivationModal'

import {
  filterUserStatusValues,
  filterUserTypesValues,
} from '../../utils/UsersMapping'

export const UserManagementGrid: FC = () => {
  const { t } = useTranslation()

  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()

  const { selectedLocation } = useLocations()
  const { userPermissionSet } = useAuthorization()
  const navigate = useNavigate()

  const gridRef = useRef<AgGridReact>(null)

  const [isActivationModalOpen, setIsActivationModalOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)

  const UserGridPrivs = ['v2.users.get']

  useEffect(() => {
    setAppBarTitle('Manage Users', undefined, ['Settings', 'Users Management'])
  }, [])

  const datasource = useMemo(() => {
    return new DataSource('users', {
      filterVariant: 'filter',
      fixedFilters: {
        location_id: selectedLocation?.id,
      },
    })
  }, [selectedLocation?.id])

  const defaultFilters = {
    status_code: {
      type: 'agSetColumnFilter',
      values: ['1'],
    },
  }

  const initialFilterModel = useFilterModel(defaultFilters)

  const handleResend = async (userId: string) => {
    try {
      await api.service('users').sendVerification(userId)
      setNotification({
        label: t('user-management.verification-email-sent'),
        type: 'success',
      })
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    }
  }

  const handleRescind = async (userId: string) => {
    try {
      await api.service('users').removeVerification(userId)
      setNotification({
        label: t('user-management.rescind-success'),
        type: 'success',
      })
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    }
  }

  const columnDefs: ColDef<User>[] = useMemo(
    () => [
      {
        headerName: t('common.name-first'),
        field: 'first_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.name-last'),
        field: 'last_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        sort: 'asc',
        sortIndex: 0,
      },
      {
        headerName: t('common.email'),
        field: 'email',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('user-management.user-type'),
        field: 'user_type_code',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterUserTypesValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterUserTypesValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = filterUserTypesValues.find(
              (option) => option.value === a
            )

            const bOption = filterUserTypesValues.find(
              (option) => option.value === b
            )

            return aOption?.label.localeCompare(bOption?.label) || 0
          }
        },
        cellRenderer: ({ value }) => <span>{userTypesCodes[value]}</span>,
        sortable: true,
      },
      {
        headerName: t('common.status'),
        field: 'status_code',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterUserStatusValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterUserStatusValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const aOption = filterUserStatusValues.find(
              (option) => option.value === a
            )

            const bOption = filterUserStatusValues.find(
              (option) => option.value === b
            )

            return aOption?.label.localeCompare(bOption?.label) || 0
          }
        },
        cellRenderer: (data) => <UserStatus statusCode={data.value} />,
        sortable: true,
      },
    ],
    [selectedLocation?.id, userPermissionSet]
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const refreshGrid = () => {
    gridRef.current!.api.refreshServerSide({ purge: true })
  }

  const threeDotsOptions = useCallback((data: User) => [
    {
      label: t('common.view'),
      action: () => {
        const portal = sessionStorage.getItem('portal')
        navigate(`/${portal}/user-management/${data.id}/view`, {
          state: {
            backUrl: `/${portal}/user-management`,
          },
        })
      },
      enabled: true,
    },
    {
      label: t('common.edit'),
      action: () => {
        const portal = sessionStorage.getItem('portal')
        navigate(`/${portal}/user-management/${data.id}/edit`, {
          state: { backUrl: `/${portal}/user-management` },
        })
      },
      enabled:
        data.status_code !== UserStatusEnum.PendingInvitation &&
        checkPermission(userPermissionSet, 'v2.users.put'),
    },
    {
      label:
        data.status_code === UserStatusEnum.Active
          ? t('common.deactivate')
          : t('common.reactivate'),
      action: () => {
        setSelectedUser(data)
        setIsActivationModalOpen(true)
      },
      enabled:
        data.status_code !== 2 &&
        checkPermission(userPermissionSet, 'v2.users.put'),
    },
    {
      label: t('user-management.resend'),
      action: () => handleResend(data.id),
      enabled:
        data.status_code === UserStatusEnum.PendingInvitation &&
        checkPermission(
          userPermissionSet,
          'v2.users.post.sendverification'
        ),
    },
    {
      label: t('user-management.rescind'),
      action: () => handleRescind(data.id),
      enabled:
        data.status_code === UserStatusEnum.PendingInvitation &&
        checkPermission(
          userPermissionSet,
          'v2.users.delete.removeverification'
        ),
    },
  ], [userPermissionSet])

  return (
    <>
      {selectedUser && (
        <UserActivationModal
          user={selectedUser}
          open={isActivationModalOpen}
          onClose={() => setIsActivationModalOpen(false)}
          onSuccess={refreshGrid}
        />
      )}
      <section>
        <HasPermission
          allPermissions={UserGridPrivs}
          unauthorizedComponent={<UserNotAllowed />}
        >
          <PaginationTable<User>
            columnDefs={columnDefs}
            rowModelType={'serverSide'}
            serverSideDatasource={datasource}
            defaultColDef={defaultColDef}
            onRowClicked={({ data }) => {
              const portal = sessionStorage.getItem('portal')
              navigate(`/${portal}/user-management/${data.id}/view`)
            }}
            showExportButton={true}
            showClearFiltersButton={true}
            initialFilterModel={initialFilterModel}
            primaryButtonData={{
              text: t('common.add-user'),
              action: () => {
                const portal = sessionStorage.getItem('portal')
                navigate(`/${portal}/user-management/add`)
              },
            }}
            serviceName={EnumServiceName.Users}
            getGridRef={getGridRef}
            guidingId="usermanagement"
            threeDotsOptions={threeDotsOptions}
          />
        </HasPermission>
      </section>
    </>
  )
}
