import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import { api, User } from '@shared/api'
import {
  PageLayoutContainer,
  PageLayoutContainerMain,
  PageLayoutDivider,
  AdvancedSettings,
  ButtonBar,
  ButtonBarEnd,
  Button,
  Loading,
  HasPermission,
} from '@shared/components'
import {
  useAuthorization,
  useFtpPortalHubCommunication,
  useNotification,
} from '@shared/hooks'
import { UserStatusEnum } from '@shared/types'
import { checkPermission, checkSomePermissions } from '@shared/utils'

import { UserActivationModal } from '@/components/user-activation-modal/UserActivationModal'

import { Invitation } from './components/invitation/Invitation'
import { LocationAccess } from './components/location-access/LocationAccess'
import { Security } from './components/security/Security'
import { UserDetails } from './components/user-details/UserDetails'
import { UserRoles } from './components/user-roles/UserRoles'

export default function UserManagementView() {
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { id } = useParams()
  const { state } = useLocation()
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const { userPermissionSet } = useAuthorization()

  const [isLoadingResend, setIsLoadingResend] = useState(false)
  const [isLoadingRescind, setIsLoadingRescind] = useState(false)

  const [user, setUser] = useState<User | null>(null)
  const [isUserActivationModalOpen, setIsUserActivationModalOpen] =
    useState(false)

  const getUser = async (userId) => {
    const user = await api.service('users').get(userId)
    setUser(user)
    const portal = sessionStorage.getItem('portal')
    setAppBarTitle(
      `${user.first_name || ''} ${user.last_name}`,
      undefined,
      [t('common.users-management'), t('common.view-user')],
      state?.backUrl || `/${portal}/user-management`
    )
  }

  useEffect(() => {
    if (!id) return

    getUser(id)
  }, [id])

  const handleResend = async () => {
    try {
      setIsLoadingResend(true)
      await api.service('users').sendVerification(id)
      setNotification({
        label: t('user-management.verification-email-sent'),
        type: 'success',
      })
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    } finally {
      setIsLoadingResend(false)
    }
  }

  const handleRescind = async () => {
    try {
      setIsLoadingRescind(true)
      await api.service('users').removeVerification(id)
      setNotification({
        label: t('user-management.rescind-success'),
        type: 'success',
      })
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    } finally {
      setIsLoadingRescind(false)
    }
  }

  if (!user) {
    return <Loading />
  }

  return (
    <HasPermission allPermissions={['v2.users.get']}>
      <>
        <UserActivationModal
          user={user}
          open={isUserActivationModalOpen}
          onClose={() => setIsUserActivationModalOpen(false)}
          onSuccess={() => getUser(id)}
        />

        {(checkSomePermissions(
          userPermissionSet,
          'v2.users.delete.removeverification',
          'v2.users.post.sendverification'
        ) ||
          user.status_code !== UserStatusEnum.PendingInvitation) && (
          <ButtonBar>
            <ButtonBarEnd>
              {user.status_code === UserStatusEnum.PendingInvitation && (
                <>
                  {checkPermission(
                    userPermissionSet,
                    'v2.users.post.sendverification'
                  ) && (
                    <Button
                      label={t('user-management.resend')}
                      style={{ marginRight: '10px' }}
                      color="secondary"
                      onClick={handleResend}
                      isLoading={isLoadingResend}
                      guidingId="usermanagement-view-resend"
                    />
                  )}
                  {checkPermission(
                    userPermissionSet,
                    'v2.users.delete.removeverification'
                  ) && (
                    <Button
                      label={t('user-management.rescind')}
                      color="secondary"
                      onClick={handleRescind}
                      isLoading={isLoadingRescind}
                      guidingId="usermanagement-view-rescind"
                    />
                  )}
                </>
              )}
              {user.status_code === UserStatusEnum.Inactive && (
                <Button
                  label={t('common.reactivate')}
                  onClick={() => setIsUserActivationModalOpen(true)}
                  guidingId="usermanagement-view-reactivate"
                />
              )}
              {user.status_code === UserStatusEnum.Active && (
                <Button
                  label={t('common.deactivate')}
                  onClick={() => setIsUserActivationModalOpen(true)}
                  guidingId="usermanagement-view-deactivate"
                />
              )}
            </ButtonBarEnd>
          </ButtonBar>
        )}

        <PageLayoutContainer>
          <PageLayoutContainerMain isFullWidth>
            {user.status_code === UserStatusEnum.PendingInvitation && (
              <>
                <Invitation />
                <PageLayoutDivider />
              </>
            )}
            <UserDetails user={user} />
            {user.status_code !== UserStatusEnum.Inactive && (
              <>
                <PageLayoutDivider />
                <UserRoles user={user} />
                <PageLayoutDivider />
                <LocationAccess user={user} />
                <PageLayoutDivider />
                <Security user={user} />
                {user.status_code === UserStatusEnum.Active && (
                  <>
                    <PageLayoutDivider />
                    <AdvancedSettings
                      user={user}
                      setUser={setUser}
                      guidingId="usermanagement-view"
                    />
                  </>
                )}
              </>
            )}
          </PageLayoutContainerMain>
        </PageLayoutContainer>
      </>
    </HasPermission>
  )
}
