import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import ErrorIcon from '@mui/icons-material/Error'

import { User } from '@shared/api'
import {
  CustomMessage,
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  UserStatus,
} from '@shared/components'
import { useAuthorization } from '@shared/hooks'
import { UserStatusEnum } from '@shared/types'
import { checkPermission, formatPhoneNumber } from '@shared/utils'

import { formatUserTypeCode } from '@/utils/UsersMapping'

interface UserDetailsProps {
  user: User
}

export function UserDetails({ user }: UserDetailsProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { userPermissionSet } = useAuthorization()

  const onEdit = () => {
    const portal = sessionStorage.getItem('portal')
    navigate(`/${portal}/user-management/${user.id}/edit`)
  }

  const isEditButtonVisible =
    checkPermission(userPermissionSet, 'v2.users.put') &&
    user.status_code !== UserStatusEnum.PendingInvitation

  return (
    <>
      {user.status_code === UserStatusEnum.Inactive && (
        <CustomMessage
          showIcon
          IconComponent={ErrorIcon}
          message={t('user-management.this-user-is-currently-inactive')}
          containerStyle={{
            backgroundColor: '#FEE2E2',
          }}
          iconStyle={{
            color: '#EF4444',
          }}
        />
      )}
      <FieldGroupContainer
        buttonData={
          isEditButtonVisible
            ? {
                label: t('common.edit'),
                onClick: onEdit,
                color: 'primary',
                guidingId: 'usermanagement-view-edit',
              }
            : undefined
        }
        title={t('user-management.user-details')}
        titleDecorator={<UserStatus statusCode={user.status_code} />}
      >
        <FieldGroupRow>
          <FieldGroupViewItem
            title={t('common.name-first')}
            value={user.first_name}
          />
          <FieldGroupViewItem
            title={t('common.name-last')}
            value={user.last_name}
          />
        </FieldGroupRow>

        <FieldGroupRow>
          <FieldGroupViewItem title={t('common.email')} value={user.email} />
          <FieldGroupViewItem
            title={t('common.phone-number')}
            value={formatPhoneNumber(user.cell_phone)}
          />
        </FieldGroupRow>

        <FieldGroupRow>
          <FieldGroupViewItem
            title={t('common.user-type')}
            value={formatUserTypeCode(user.user_type_code)}
          />
          <FieldGroupViewItem title={t('common.timezone')} value={user.tz} />
        </FieldGroupRow>
      </FieldGroupContainer>
    </>
  )
}
