import { tss } from 'tss-react/mui'

import accountAch from '@shared/images/account-ach.svg'
import accountCash from '@shared/images/account-cash.png'
import accountCc from '@shared/images/account-cc.svg'
import ccAmex from '@shared/images/cc-amex.svg'
import ccDiners from '@shared/images/cc-diners.svg'
import ccDiscover from '@shared/images/cc-discover.svg'
import ccJcb from '@shared/images/cc-jcb.svg'
import ccMc from '@shared/images/cc-mc.png'
import ccVisa from '@shared/images/cc-visa.png'
import ebt from '@shared/images/ebt.png'
import {
  accountTypeLabel,
  AccountTypeProps,
  TransactionAccountType,
} from '@shared/types'

const accountTypeToCardText: Record<string, string> = {
  amex: ccAmex,
  diners: ccDiners,
  disc: ccDiscover,
  discover: ccDiscover,
  jcb: ccJcb,
  mc: ccMc,
  mastercard: ccMc,
  visa: ccVisa,
  checking: accountAch,
  'personal checking': accountAch,
  'business checking': accountAch,
  savings: accountAch,
  'personal savings': accountAch,
  'business savings': accountAch,
  debit: accountCc,
  cash: accountCash,
  ebt: ebt,
}

// Manual mapping for values returned as code from API (such as endpoints that use datahub)
const mapCodeToAccountType = (code: string) => {
  switch (code) {
    case '11':
      return TransactionAccountType['PERSONAL CHECKING'].toLowerCase()
    case '12':
      return TransactionAccountType['BUSINESS CHECKING'].toLowerCase()
    case '13':
      return TransactionAccountType['PERSONAL SAVINGS'].toLowerCase()
    case '14':
      return TransactionAccountType['BUSINESS SAVINGS'].toLowerCase()
  }
  return code
}

const useStyles = tss.withName('AccountType').create({
  main: {
    display: 'flex',
  },
  label: {
    marginLeft: '10px',
  },
})

function AccountType({
  type,
  showImage = true,
  showLabel = true,
  style,
  isEbt = false,
}: AccountTypeProps) {
  const { classes } = useStyles()
  let accountType = mapCodeToAccountType(type?.toLowerCase())
  if (isEbt) {
    accountType = 'ebt'
  }

  if (accountType) {
    if (!accountTypeLabel[accountType]) {
      showImage = false
    }
    return (
      <div className={classes.main} style={style}>
        {showImage ? (
          <div>
            <img src={accountTypeToCardText[accountType]} alt={accountType} />
          </div>
        ) : null}
        {showLabel && (
          <span className={classes.label}>{accountTypeLabel[accountType]}</span>
        )}
      </div>
    )
  } else {
    return <div className="flex"></div>
  }
}

export default AccountType
