import { yupResolver } from '@hookform/resolvers/yup'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Typography } from '@mui/material'

import { Input } from '@shared/components'
import { useNotification } from '@shared/hooks'

import { Transaction, api } from '../../../api/src'
import { ActionModal } from '../../modals/action-modal/ActionModal'

const useStyles = tss.withName('EmailReceiptModal').create(({ theme }) => ({
  description: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.palette['neutral-600'],
    marginBottom: '1em',
  },
}))

interface FormData {
  email: string
}

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email address')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Invalid email address'),
})

export interface EmailReceiptModalProps {
  transaction: Transaction
  open: boolean
  onClose: () => void | SyntheticEvent<any, Event>
}

const EmailReceiptModal: React.FC<EmailReceiptModalProps> = ({
  transaction,
  open,
  onClose,
}) => {
  const { classes } = useStyles()
  const { setNotification } = useNotification()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    setValue,
    setError,
    trigger,
  } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  useEffect(() => {
    if (transaction?.contact?.email) {
      setValue('email', transaction.contact.email)
      trigger('email')
    } else {
      reset()
    }
  }, [open, transaction])

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsLoading(true)
    try {
      await api.service('notifications').create({
        type: 'transaction',
        email: data.email,
        transaction_id: transaction.id,
      })

      setNotification({
        type: 'success',
        label: 'Email sent successfully',
      })
      closeModal()
    } catch (error) {
      setError('email', {
        type: 'manual',
        message: error.message,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const closeModal = () => {
    reset()
    onClose()
  }

  return (
    <ActionModal
      open={open}
      onClose={closeModal}
      title="Email Receipt"
      buttons={[
        {
          testId: 'cancel-button',
          label: 'Cancel',
          color: 'secondary',
          onClick: closeModal,
          guidingId: 'emailreceipt-cancel',
        },
        {
          testId: 'sendreceipt-button',
          label: 'Send Receipt',
          disabled: !isValid,
          isLoading: isLoading,
          onClick: handleSubmit(onSubmit),
          guidingId: 'emailreceipt-sendreceipt',
        },
      ]}
      guidingId="emailreceipt"
    >
      <form>
        <Typography className={classes.description}>
          Send an email receipt to:
        </Typography>

        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              testId="email-input"
              label="Email"
              placeholder="Enter Email Address"
              error={!!errors.email}
              helperText={errors.email?.message}
              guidingId="emailreceipt-emailaddress"
            />
          )}
        />
      </form>
    </ActionModal>
  )
}

export default EmailReceiptModal
