import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { ActionModal } from '@shared/components'

interface DeleteLocationUserModalProps {
  isOpen: boolean
  onClose: () => void
  onDelete: () => void
  guidingId?: string
}

export const DeleteLocationUserModal: FC<DeleteLocationUserModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  guidingId,
}) => {
  const { t } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleDelete = async () => {
    setIsSubmitting(true)
    await onDelete()
    setIsSubmitting(false)
    onClose()
  }

  return (
    <ActionModal
      open={isOpen}
      onClose={onClose}
      title={t('user-management.delete-location-user')}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
          color: 'secondary',
          guidingId: `${guidingId}-deletelocationuser-cancel`,
        },
        {
          label: t('common.delete'),
          onClick: handleDelete,
          color: 'primary',
          isLoading: isSubmitting,
          guidingId: `${guidingId}-deletelocationuser-delete`,
        },
      ]}
      guidingId={`${guidingId}-deletelocationuser`}
    >
      <Typography>
        {t('user-management.delete-location-user-description')}
      </Typography>
    </ActionModal>
  )
}
