import { MerchantAccount } from '../api/src'
import {
  TransactionSourceCode,
  transactionSourceCodes,
  GatewayTransactionEntryModeKey,
  gatewayTransactionsEntryModes,
  reasonCodes,
  ProcessingStatusCodeType,
  TransactionStatusType,
  transactionStatusCodes,
  BatchStatusType,
  batchStatusCodes,
  transactionTypes,
  TransactionAccountType,
  secCodes,
  avsResponses,
  PaymentMethodTypeString,
  achAccountTypeCodes,
  achTransactionCode,
  SettledTransactionCCAccountType,
  SettledTransactionACHAccountType,
  settledTransactionTypes,
  EbtTypes,
  EbtBalanceTypes,
} from '../types'
import { sortMerchantAccounts } from '../utils'

export const processingCCStatusCodes: Record<string, ProcessingStatusCodeType> =
  {
    Settled: ProcessingStatusCodeType.SETTLED,
    Processed: ProcessingStatusCodeType.PROCESSED,
    Reject: ProcessingStatusCodeType.REJECT,
  }

export const mapTransactionTypeId = (
  type_id: string | number | undefined,
  ebt_type?: string | null
) => {
  if (ebt_type && type_id) {
    return 'EBT ' + transactionTypes[type_id.toString()]
  }
  return type_id ? transactionTypes[type_id.toString()] : ''
}

export const mapEbtType = (ebt_type: string) => {
  return EbtTypes[ebt_type]
}

export const mapEbtBalanceType = (account_type: string) => {
  return EbtBalanceTypes[account_type]
}

export const codeToTransactionStatus = (
  code: string | number | null | undefined
): TransactionStatusType => {
  if (!code) return <TransactionStatusType>''
  return transactionStatusCodes[code.toString()]
}

export const codeToBatchTransactionStatus = (
  code: string | number | null | undefined
): BatchStatusType => {
  if (!code) return <BatchStatusType>''
  return batchStatusCodes[code.toString()]
}

export const codeToReason = (
  code: string,
  payment_method?: string,
  ebt_type?: string | null
): string => {
  if (payment_method && code === '1000') {
    return ebt_type
      ? 'EBT - ' + reasonCodes[`${payment_method}${code}`]
      : reasonCodes[`${payment_method}${code}`]
  }
  return ebt_type ? 'EBT - ' + reasonCodes[code] : reasonCodes[code]
}

export const codeToEntryMode = (
  code: GatewayTransactionEntryModeKey
): string => {
  return gatewayTransactionsEntryModes[code]
}

export const codeToSource = (code: TransactionSourceCode): string => {
  return transactionSourceCodes[code]
}

export const filterTransactionTypeValues = Object.entries(transactionTypes).map(
  ([key, value]) => ({ value: key, label: value })
)

export const filterTransactionStatusValues = Object.entries(
  transactionStatusCodes
).map(([key, value]) => ({ value: key, label: value }))

export const filterBatchTransactionStatusValues = Object.entries(
  batchStatusCodes
).map(([key, value]) => ({ value: key, label: value }))

export const filterSettledTransactionTypesValues = settledTransactionTypes.map(
  (item: { code: string; description: string }) => ({
    value: item.code,
    label: item.description,
  })
)

export const filterAccountTypeValues = Object.entries(
  TransactionAccountType
).map(([key, value]) => ({ value: key, label: value }))

export const filterAccountTypeCCValues = Object.entries(
  SettledTransactionCCAccountType
).map(([key, value]) => ({ value: key, label: value }))

export const filterAccountTypeACHValues = Object.entries(
  SettledTransactionACHAccountType
).map(([key, value]) => ({ value: key, label: value }))

export const reasonDescriptionFilterOptions = Object.entries(reasonCodes).map(
  ([key, value]) => ({ value: key, label: value })
)

export const ebtTypeFilterOptions = Object.entries(EbtTypes).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

export const entryModeFilterOptions = Object.entries(
  gatewayTransactionsEntryModes
).map(([key, value]) => ({ value: key, label: value }))

export const trxSourceFilterOtions = Object.entries(transactionSourceCodes).map(
  ([key, value]) => ({ value: key, label: value })
)

export const secCodeFilterOptions = Object.entries(secCodes).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)

export const secCodeFilterOptionsDatahub = secCodeFilterOptions.map((option) =>
  option.value.toLocaleUpperCase()
)

export const filterPaymentMethod = Object.entries(PaymentMethodTypeString).map(
  ([key, value]) => ({ value: key, label: value })
)

export const filterAvsResponse = Object.entries(avsResponses).map(
  ([key, value]) => ({ value: key, label: value })
)

export const filterMerchantAccount = (MerchantAccounts: MerchantAccount[]) => {
  const sortedMerchantAccounts = sortMerchantAccounts(MerchantAccounts)
  return Object.entries(sortedMerchantAccounts).map(([key, value]) => ({
    value: value?.id,
    label: value?.title,
  }))
}

export const filterAchAccountTypeValues = Object.entries(
  achAccountTypeCodes
).map(([key, value]) => ({ value: key, label: value }))

export const filterAchTransactionType = Object.entries(achTransactionCode).map(
  ([key, value]) => ({
    value: key,
    label: value,
  })
)
