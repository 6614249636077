export enum TransactionStatusType {
  'APPROVED' = 'Approved',
  'AUTH_ONLY' = 'AuthOnly',
  'REFUNDED' = 'Refunded',
  'AVSONLY' = 'AvsOnly',
  'COMMUNICATING' = 'Communicating',
  'PENDING_ORIGINATION' = 'Pending-origination',
  'ORIGINATING' = 'Originating',
  'ORIGINATED' = 'Originated',
  'SETTLED_ACH' = 'Settled (ACH)',
  'CASH_APPROVED' = 'Cash Approved',
  'CASH_REFUNDED' = 'Cash Refunded',
  'BATCH_SETTLED' = 'Batch Settled',
  'VOIDED' = 'Voided',
  'CASH_VOIDED' = 'Cash Voided',
  'DECLINED' = 'Declined',
  'CHARGED_BACK' = 'Charged Back',
  'BALANCE_INQUIRY' = 'Balance Inquiry',
}

export const transactionStatusCodes: Record<string, TransactionStatusType> = {
  '100': TransactionStatusType.BALANCE_INQUIRY,
  '101': TransactionStatusType.APPROVED,
  '102': TransactionStatusType.AUTH_ONLY,
  '111': TransactionStatusType.REFUNDED,
  '121': TransactionStatusType.AVSONLY,
  '130': TransactionStatusType.COMMUNICATING,
  '131': TransactionStatusType.PENDING_ORIGINATION,
  '132': TransactionStatusType.ORIGINATING,
  '133': TransactionStatusType.ORIGINATED,
  '134': TransactionStatusType.SETTLED_ACH,
  '140': TransactionStatusType.CASH_APPROVED,
  '141': TransactionStatusType.CASH_REFUNDED,
  '201': TransactionStatusType.VOIDED,
  '202': TransactionStatusType.CASH_VOIDED,
  '301': TransactionStatusType.DECLINED,
  '331': TransactionStatusType.CHARGED_BACK,
}
