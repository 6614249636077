import React, { ElementType, FC, forwardRef } from 'react'
import { tss } from 'tss-react/mui'

import {
  Box,
  IconButton,
  InputBaseComponentProps,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'

import { HelpInfo } from '@shared/icons'

type StylesParams = {
  isTextArea?: boolean
  error?: boolean
  noHoverBorder?: boolean
  isLeftCombined?: boolean
}

export const useInputStyles = tss
  .withName('Input')
  .withParams<StylesParams>()
  .create(({ theme, isTextArea, error, noHoverBorder }) => ({
    labelContainer: {
      display: 'flex',
      marginBottom: '.6em',
      alignItems: 'center',
    },
    label: {
      color: `${theme.palette['neutral-700']} !important`,
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '20px !important',
    },
    required: {
      color: `${theme.palette['negative']} !important`,
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '20px !important',
      marginLeft: '3px !important',
    },
    input: {
      '& input, & textarea': {
        padding: !isTextArea ? '21px !important' : '4px 0 !important',
        fontFamily: 'Inter !important',
        fontSize: '14px !important',
        fontWeight: '500 !important',
        lineHeight: '20px !important',
        boxSizing: 'border-box',
        borderRadius: '4px',
        border:
          !!error && noHoverBorder
            ? `1px solid ${theme.palette['negative']}`
            : noHoverBorder
            ? '1px solid #bdbdbd'
            : undefined,

        ':focus': {
          border: !!error
            ? `1px solid ${theme.palette['negative']}`
            : undefined,
        },
      },
      '& label.Mui-focused': {
        color: theme.palette['neutral-700'],
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette['neutral-300'],
        borderWidth: '1px',
      },
    },
    textAreaCharsCounter: {
      position: 'absolute',
      right: '0',
      marginTop: '.5em',
      color: theme.palette['secure-gray-75'],
    },
    helperText: {
      marginLeft: '0 !important',
      marginTop: '4px !important',
      fontFamily: 'Inter !important',
      fontSize: '12px !important',
      fontWeight: '500 !important',
      lineHeight: '16px !important',
      color: `${theme.palette['neutral-500']} !important`,
    },
    helperTextError: {
      color: `${theme.palette['negative']} !important`,
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    iconButton: { padding: '0.38em 0em 0em', margin: '0 .08em' },
  }))

export interface InputProps {
  id?: string
  label?: string
  value?: string | number
  placeholder?: string
  mask?: ElementType<InputBaseComponentProps>
  onChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  type?: string
  error?: boolean
  helperText?: string
  required?: boolean
  infoText?: string
  disabled?: boolean
  icon?: Icon | Icon[]
  endAdornmentText?: string
  startAdornmentText?: string
  /**
   * If `true` the input will not have a border on hover/focus unless overrided.
   * This helps for inputs that are stuck together for the border on hover not to overlap.
   *
   * Defaults to `false`.
   */
  noHoverBorder?: boolean
  style?: React.CSSProperties
  className?: string
  testId?: string
  isTextArea?: boolean
  inputProps?: InputBaseComponentProps
  autoFocus?: boolean
  guidingId?: string
  isLeftCombined?: boolean
}

const Input = forwardRef<unknown, InputProps>(
  (
    {
      id,
      label,
      value,
      placeholder,
      mask,
      onChange,
      onBlur,
      type,
      error = false,
      helperText,
      required = false,
      infoText,
      disabled = false,
      icon,
      endAdornmentText,
      startAdornmentText,
      noHoverBorder,
      style,
      className,
      testId,
      isTextArea,
      inputProps,
      autoFocus,
      guidingId,
      isLeftCombined,
    },
    ref
  ) => {
    const { classes, cx } = useInputStyles({
      isTextArea,
      error,
      noHoverBorder,
      isLeftCombined,
    })

    return (
      <Box
        sx={{ ...style, position: 'relative' }}
        data-guiding-id={`${guidingId}-container`}
      >
        <Input.Label
          text={label}
          required={required}
          testId={testId}
          infoText={infoText}
        />

        <TextField
          multiline={isTextArea}
          inputRef={ref}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          type={type}
          error={error}
          helperText={helperText}
          disabled={disabled}
          className={cx(classes.input, className)}
          fullWidth
          variant={noHoverBorder ? 'standard' : 'outlined'}
          size="small"
          inputProps={{
            'data-testid': testId,
            'data-guiding-id': guidingId,
            style: { height: '44px' },
            ...inputProps,
          }}
          InputProps={{
            ...(noHoverBorder ? { disableUnderline: true } : {}), // disableUnderline is not supported on outlined variant
            endAdornment:
              icon || !!endAdornmentText ? (
                <>
                  {icon ? (
                    <Input.Icon id={id} icon={icon} guidingId={guidingId} />
                  ) : (
                    !!endAdornmentText && (
                      <Typography>{endAdornmentText}</Typography>
                    )
                  )}
                </>
              ) : null,
            startAdornment: startAdornmentText && (
              <Typography>{startAdornmentText}</Typography>
            ),
            inputComponent: mask ? mask : 'input',
            style: isLeftCombined && {
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
            },
          }}
          FormHelperTextProps={{
            id: `${testId}-helper`,
            className: `${classes.helperText} ${
              error ? classes.helperTextError : ''
            }`,
          }}
          autoFocus={autoFocus}
        />

        {isTextArea && inputProps.maxLength && (
          <Typography className={classes.textAreaCharsCounter} variant="body2">
            {`${value ? String(value).length : '0'}/${inputProps.maxLength}`}
          </Typography>
        )}
      </Box>
    )
  }
) as React.ForwardRefExoticComponent<
  InputProps & React.RefAttributes<unknown>
> & { Label: typeof InputLabel; Icon: typeof InputIcon }

interface InputLabelProps {
  text?: string
  required?: boolean
  infoText?: string
  testId?: string
  guidingId?: string
}

const InputLabel: FC<InputLabelProps> = ({
  text,
  required,
  infoText,
  testId,
  guidingId,
}) => {
  const { classes } = useInputStyles()

  if (!text) return null

  return (
    <Box className={classes.labelContainer}>
      <Typography className={classes.label}>{text}</Typography>

      {required && (
        <Typography
          className={classes.required}
          data-testid={testId ? `${testId}-required` : null}
        >
          *
        </Typography>
      )}

      {infoText && (
        <Tooltip
          title={infoText}
          placement="top-end"
          data-guiding-id={`${guidingId}-tooltip`}
        >
          <IconButton sx={{ padding: 0, marginLeft: '4px' }}>
            <HelpInfo sx={{ width: '20px', height: '20px' }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  )
}

type Icon = {
  key?: string
  component: JSX.Element
  onClick?: React.MouseEventHandler<HTMLDivElement>
  guidingId?: string
}

interface InputIconProps
  extends Pick<InputProps, 'id' | 'icon' | 'guidingId'> {}

const InputIcon: FC<InputIconProps> = ({ id, icon, guidingId }) => {
  const { classes } = useInputStyles()

  if (!icon) return null

  return Array.isArray(icon) ? (
    <Box className={classes.iconContainer}>
      {icon
        .filter((icon) => !!icon)
        .map((icon, index) => (
          <Box
            key={`${id ?? icon.key}-icon-${index}`}
            className={classes.iconButton}
            sx={{ cursor: icon.onClick ? 'pointer' : 'default' }}
            component={'div'}
            onClick={icon.onClick}
            data-guiding-id={`${guidingId}-icon`}
          >
            {icon.component}
          </Box>
        ))}
    </Box>
  ) : (
    <Box
      className={classes.iconButton}
      sx={{ cursor: icon.onClick ? 'pointer' : 'default' }}
      component={'div'}
      onClick={icon.onClick}
      data-guiding-id={`${guidingId}-icon`}
    >
      {icon.component}
    </Box>
  )
}

Input.Label = InputLabel
Input.Icon = InputIcon

export default Input
