export enum TransactionType {
  CC = 'cc',
  ACH = 'ach',
  'EBT_BALANCE_INQUIRY' = 'Balance Inquiry',
  'SALE' = 'Sale',
  'AUTH_ONLY' = 'Auth Only',
  'AVS_ONLY' = 'AVS Only',
  'SETTLE' = 'Settle',
  'REFUND' = 'Refund',
  'CREDIT' = 'Credit',
  'DEBIT' = 'Debit',
}

export enum EbtTransactionType {
  'cash_benefit' = 'Cash Benefit',
  'food_stamp' = 'Food Stamp',
}

export enum EbtBalanceType {
  'cash_benefit' = 'Cash Benefit Balance',
  'food_stamp' = 'Food Stamp Balance',
}

export const transactionTypes: { [key: string]: string } = {
  '10': TransactionType.EBT_BALANCE_INQUIRY,
  '20': TransactionType.SALE,
  '21': TransactionType.AVS_ONLY,
  '22': TransactionType.SETTLE,
  '30': TransactionType.REFUND,
  '40': TransactionType.CREDIT,
  '50': TransactionType.DEBIT,
}

export const EbtTypes: { [key: string]: string } = {
  cash_benefit: EbtTransactionType.cash_benefit,
  food_stamp: EbtTransactionType.food_stamp,
}

export const EbtBalanceTypes: { [key: string]: string } = {
  'Cash Benefit': EbtBalanceType.cash_benefit,
  'Food Stamp': EbtBalanceType.food_stamp,
}
