import { useTranslation } from 'react-i18next'

import { User } from '@shared/api'
import Status, { StatusProps } from '@shared/components/status/base/Status'
import { userStatusCodes, UserStatusType } from '@shared/types'

interface UserStatusProps {
  statusCode: User['status_code']
}

const formatUserStatus = (status: number) => {
  return userStatusCodes[status]
}

const UserStatus = ({ statusCode }: UserStatusProps) => {
  const { t } = useTranslation()
  const getStatus = (status: UserStatusType): StatusProps => {
    let statusData: StatusProps = {
      text: undefined,
      backgroundColor: undefined,
    }

    switch (status) {
      case UserStatusType.PENDING_ACTIVATION:
        statusData.text = t('common.pending-activation')
        statusData.backgroundColor = 'yellow'
        break
      case UserStatusType.ACTIVE:
        statusData.text = t('common.active')
        statusData.backgroundColor = 'teal'
        break
      case UserStatusType.INACTIVE:
        statusData.text = t('common.inactive')
        statusData.backgroundColor = 'gray'
        break
      default:
        statusData.text = t('common.unknown')
        statusData.backgroundColor = 'gray'
        break
    }

    return statusData
  }

  const { text, backgroundColor } = getStatus(formatUserStatus(statusCode))

  return <Status text={text} backgroundColor={backgroundColor} />
}

export default UserStatus
