import React, { SyntheticEvent } from 'react'
import { tss } from 'tss-react/mui'

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import { Alert, AlertColor, Fade, Snackbar } from '@mui/material'

import { NegativeIcon } from '../../../icons/Negative'
import { SuccessIcon } from '../../../icons/Success'

const useStyles = tss.withName('Notification').create(({ theme }) => ({
  alert: {
    width: '335px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter !important',
    fontStyle: 'normal !important',
    fontSize: '16px !important',
    fontWeight: '500 !important',
    lineHeight: '24px !important',
  },
}))

export interface NotificationProps {
  label: string
  show: boolean
  type?: AlertColor
  duration?: number
  onClose?: () => void | SyntheticEvent<any, Event>
}

const Notification: React.FC<NotificationProps> = ({
  label,
  show,
  type = 'success',
  duration = 3000,
  onClose,
}) => {
  const { classes } = useStyles()

  // Handling minimal duration and transition duration to avoid weird behavior
  return (
    <Snackbar
      data-testid="notification"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={show}
      autoHideDuration={Math.max(duration - 1000, 2000)}
      transitionDuration={1000}
      onClose={onClose}
      TransitionComponent={Fade}
    >
      <Alert
        data-testid="notification-alert"
        className={classes.alert}
        severity={type}
        variant="filled"
        iconMapping={{
          success: <SuccessIcon sx={{ height: '32px', width: '32px' }} />,
          error: <NegativeIcon sx={{ height: '32px', width: '32px' }} />,
          info: (
            <ErrorRoundedIcon
              sx={{
                height: '32px',
                width: '32px',
              }}
            />
          ),
        }}
      >
        {label}
      </Alert>
    </Snackbar>
  )
}

export default Notification
