import { yupResolver } from '@hookform/resolvers/yup'
import { omit } from 'lodash'
import { forwardRef, MutableRefObject } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { api, User } from '@shared/api'
import {
  Checkbox,
  FieldGroupContainer,
  FieldGroupRow,
  UserDetailsForm,
  buildUserDetailsSchema,
} from '@shared/components'
import { useLocations } from '@shared/hooks'
import { processPhone, toArrayFieldErrors } from '@shared/utils'

interface UserDetailsProps {
  onSuccess: (user: User) => void
  setIsSubmitting: (value: boolean) => void
  guidingId?: string
}

export const UserDetails = forwardRef(
  (
    { onSuccess, setIsSubmitting, guidingId }: UserDetailsProps,
    ref: MutableRefObject<HTMLFormElement>
  ) => {
    const { t } = useTranslation()
    const userSchema = buildUserDetailsSchema(t).shape({
      api_only: yup.boolean(),
    })
    const { selectedLocation } = useLocations()

    const methods = useForm<yup.InferType<typeof userSchema>>({
      defaultValues: {
        last_name: '',
        email: '',
        cell_phone: '',
        first_name: '',
      },
      resolver: yupResolver(userSchema),
      mode: 'onBlur',
    })

    const onSubmit = async (data: yup.InferType<typeof userSchema>) => {
      try {
        setIsSubmitting(true)
        const userCreated = await api.service('users').create({
          ...data,
          first_name: data.first_name || null,
          user_type_code: data.user_type_code as User['user_type_code'],
          is_invitation: data.api_only ? false : true,
          primary_location_id: selectedLocation.id,
          username: data.email,
          cell_phone: data.cell_phone ? processPhone(data.cell_phone) : null,
        })
        onSuccess(userCreated)
      } catch (error) {
        const errorsFormatted = toArrayFieldErrors(error)

        errorsFormatted.forEach((e) => {
          // API needs to fix mapping for error on this field, should return as 'user_type_code'
          if (e.field === 'user_type_id') {
            e.field = 'user_type_code'
          }
          methods.setError(e.field, {
            type: 'manual',
            message: e.message,
          })
        })
      } finally {
        setIsSubmitting(false)
      }
    }

    return (
      <FieldGroupContainer
        title={t('user-management.user-details')}
        titleTestID="user-details-title"
      >
        <FormProvider {...methods}>
          <form ref={ref} onSubmit={methods.handleSubmit(onSubmit)}>
            <UserDetailsForm guidingId={guidingId} />
            <FieldGroupRow>
              <Controller
                name="api_only"
                control={methods.control}
                render={({ field }) => (
                  <Checkbox
                    {...omit(field, ['ref'])}
                    testId="api-only-input"
                    checked={!!field.value}
                    guidingId={`${guidingId}-apionly`}
                    label={t('user-management.api-only-user')}
                    description={t('user-management.api-only-user-description')}
                  />
                )}
              />
            </FieldGroupRow>
          </form>
        </FormProvider>
      </FieldGroupContainer>
    )
  }
)
