import { ApiService, App } from '../../commons'
import { expandQuery } from '../../hooks/expandQuery'
import { mapResult } from '../../hooks/mapResult'
import { paginationQuery } from '../../hooks/paginationQuery'
import {
  TerminalTransactionResponse,
  Transaction,
  TransactionsDataTypes,
  VoidTransactionData,
} from '../../schemas/types'

export class TransactionsService extends ApiService<Transaction> {
  async void(
    transactionID: string,
    { description, tags }: VoidTransactionData
  ) {
    return this.apiRequest<Transaction>({
      url: `${transactionID}/void`,
      method: 'PATCH',
      body: { description, tags },
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async reprintReceipt(transactionID: string) {
    return this.apiRequest<void>({
      url: `${transactionID}/reprint-receipt`,
      method: 'PUT',
    })
  }
}

declare module '../../commons' {
  interface ServiceTypes {
    transactions: TransactionsService
    'transaction-cc-authonly': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/auth-only/keyed']
    >
    'transaction-cc-avsonly': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/avs-only/keyed']
    >
    'transaction-cc-force': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/force/keyed']
    >
    'transaction-cc-refund': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/refund/keyed']
    >
    'transaction-cc-sale': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/sale/keyed']
    >

    'transaction-cc-authonly-token': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/sale/token']
    >
    'transaction-cc-avsonly-token': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/sale/token']
    >
    'transaction-cc-force-token': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/sale/token']
    >
    'transaction-cc-refund-token': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/sale/token']
    >
    'transaction-cc-sale-token': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/sale/token']
    >
    'transaction-cc-authonly-terminal': ApiService<
      TerminalTransactionResponse,
      TransactionsDataTypes['transactions/cc/auth-only/terminal']
    >
    'transaction-cc-avsonly-terminal': ApiService<
      TerminalTransactionResponse,
      TransactionsDataTypes['transactions/cc/avs-only/terminal']
    >
    'transaction-cc-refund-terminal': ApiService<
      TerminalTransactionResponse,
      TransactionsDataTypes['transactions/cc/refund/terminal']
    >
    'transaction-cc-sale-terminal': ApiService<
      TerminalTransactionResponse,
      TransactionsDataTypes['transactions/cc/sale/terminal']
    >
    'transaction-ach-credit': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/ach/credit/keyed']
    >
    'transaction-ach-debit': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/ach/debit/keyed']
    >
    'transaction-ach-credit-token': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/ach/credit/token']
    >
    'transaction-ach-debit-token': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/ach/debit/token']
    >
    'transaction-cash-sale': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cash/sale']
    >
    'transaction-cash-refund': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cash/refund']
    >
    'transaction-ebt-voucherclearsale': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/ebt/voucher-clear-sale/keyed']
    >
    'transaction-ebt-voucherclearrefund': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/ebt/voucher-clear-refund/keyed']
    >
    'transaction-cc-balanceinquiry-terminal': ApiService<
      Transaction,
      TransactionsDataTypes['transactions/cc/balance-inquiry/terminal']
    >
  }
}

export const transactionServiceMappings = {
  'transaction-cc-authonly': 'transactions/cc/auth-only/keyed',
  'transaction-cc-avsonly': 'transactions/cc/avs-only/keyed',
  'transaction-cc-force': 'transactions/cc/force/keyed',
  'transaction-cc-refund': 'transactions/cc/refund/keyed',
  'transaction-cc-sale': 'transactions/cc/sale/keyed',
  'transaction-cc-authonly-terminal': 'transactions/cc/auth-only/terminal',
  'transaction-cc-avsonly-terminal': 'transactions/cc/avs-only/terminal',
  'transaction-cc-refund-terminal': 'transactions/cc/refund/terminal',
  'transaction-cc-sale-terminal': 'transactions/cc/sale/terminal',
  'transaction-cc-authonly-token': 'transactions/cc/auth-only/token',
  'transaction-cc-avsonly-token': 'transactions/cc/avs-only/token',
  'transaction-cc-force-token': 'transactions/cc/force/token',
  'transaction-cc-refund-token': 'transactions/cc/refund/token',
  'transaction-cc-sale-token': 'transactions/cc/sale/token',
  'transaction-ach-credit-token': 'transactions/ach/credit/token',
  'transaction-ach-debit-token': 'transactions/ach/debit/token',
  'transaction-ach-credit': 'transactions/ach/credit/keyed',
  'transaction-ach-debit': 'transactions/ach/debit/keyed',
  'transaction-cash-sale': 'transactions/cash/sale',
  'transaction-cash-refund': 'transactions/cash/refund',
  'transaction-ebt-voucherclearsale':
    'transactions/ebt/voucher-clear-sale/keyed',
  'transaction-ebt-voucherclearrefund':
    'transactions/ebt/voucher-clear-refund/keyed',
  'transaction-cc-balanceinquiry-terminal':
    'transactions/cc/balance-inquiry/terminal',
} as const

export const transactionsService = (app: App) => {
  const connection = app.get('connection')

  const commonExpandFields = [
    'contact',
    'product_transaction',
    'quick_invoice',
    'tags',
    'surcharge',
    'recurring',
    'status',
    'terminal',
  ]

  const transactionDetailsExpandFields = [
    ...commonExpandFields,
    'transaction_histories',
    'is_voidable',
    'balances',
    'is_retriable',
    'rejected_transaction_ach_retries',
    'retry_transaction_ach_retry',
    'return_fee_transaction_ach_retry',
  ]

  app.use(
    'transactions',
    new TransactionsService(connection.service('transactions'), app)
  )

  app.service('transactions').hooks({
    around: {
      all: [paginationQuery(), mapResult()],
      find: [expandQuery(...commonExpandFields)],
      get: [expandQuery(...transactionDetailsExpandFields)],
    },
  })

  Object.keys(transactionServiceMappings).forEach((key) => {
    const name = key as keyof typeof transactionServiceMappings
    const path = transactionServiceMappings[name]

    app.use(name, new ApiService<any>(connection.service(path), app))

    app.service(name).hooks({
      around: {
        all: [mapResult()],
      },
    })
  })
}
