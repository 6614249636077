import { LicenseManager } from 'ag-grid-enterprise'
import { useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import packageJSON from '@/../package.json'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import { User, api } from '@shared/api'
import { ErrorHandler } from '@shared/components'
import AppTheme from '@shared/design'
import {
  EnforceLoginProvider,
  AuthorizationProvider,
  FtpPortalHubCommunicationProvider,
  useFtpPortalHubCommunication,
  LocationProvider,
  ReportFiltersProvider,
  NotificationProvider,
  useLocations,
} from '@shared/hooks'

import i18n from './i18n'
import { UserManagementAdd } from './pages/user-management-add/UserManagementAdd'
import UserManagementEdit from './pages/user-management-edit/UserManagementEdit'
import UserManagementView from './pages/user-management-view/UserManagementView'
import { UserManagementGrid } from './pages/UserManagementGrid/UserManagementGrid'

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE)

export const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'token'

const UserManagementApp = () => {
  const { setPortalVersion } = useFtpPortalHubCommunication()
  const { selectedLocation, setSelectedLocation } = useLocations()

  const [user, setUser] = useState<User | null>(null)

  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()

  const pages = useMemo(() => {
    const root_url = `${
      window?.location?.pathname?.split('/user-management')[0]
    }`
    return [
      // This first route is just for testing purposes
      new AddressablePage('', <></>, [], '/'),
      new AddressablePage(
        'User Management',
        <UserManagementGrid />,
        [],
        `${root_url}/user-management`
      ),
      new AddressablePage(
        'User Management View',
        <UserManagementView />,
        [],
        `${root_url}/user-management/:id/view`,
        undefined,
        true
      ),
      new AddressablePage(
        'User Management Edit',
        <UserManagementEdit />,
        [],
        `${root_url}/user-management/:id/edit`,
        undefined,
        true
      ),
      new AddressablePage(
        'User Management Add',
        <UserManagementAdd />,
        [],
        `${root_url}/user-management/add`,
        undefined,
        true
      ),
    ]
  }, [user])

  useEffect(() => {
    api
      .service('users')
      .authorize()
      .then(setUser)
      .catch((error) => {
        console.error('ERROR GETTING/SETTING USER DATA:', error.toString())
      })

    setPortalVersion({ version: packageJSON.version })
  }, [])

  useEffect(() => {
    if (!user) return

    setAddressablePageManager(new AddressablePageManager(pages, user))

    const handleLocationChange = async (event) => {
      await setSelectedLocation(event.detail.location)
    }

    window.addEventListener(
      'FTP_NAVIGATOR_LOCATION_CHANGED',
      handleLocationChange
    )

    return () => {
      window.removeEventListener(
        'FTP_NAVIGATOR_LOCATION_CHANGED',
        handleLocationChange
      )
    }
  }, [user, selectedLocation?.id, setSelectedLocation])

  if (!addressablePageManager) return null

  return (
    <AuthorizationProvider user={user}>
      <ThemeProvider theme={AppTheme}>
        <Routes>{addressablePageManager.renderAllAddressablePages()}</Routes>
      </ThemeProvider>
    </AuthorizationProvider>
  )
}

const UserManagement: React.FC = () => (
  <FtpPortalHubCommunicationProvider>
    <I18nextProvider i18n={i18n}>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <EnforceLoginProvider redirectToLogin>
          <NotificationProvider>
            <BrowserRouter>
              <ReportFiltersProvider>
                <LocationProvider>
                  <UserManagementApp />
                </LocationProvider>
              </ReportFiltersProvider>
            </BrowserRouter>
          </NotificationProvider>
        </EnforceLoginProvider>
      </ErrorBoundary>
    </I18nextProvider>
  </FtpPortalHubCommunicationProvider>
)

export default UserManagement
