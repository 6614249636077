import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { api, LocationInfo, LocationUser, User } from '@shared/api'
import {
  FieldGroupContainer,
  GetItemsOptions,
  ListTypeahead,
  Loading,
} from '@shared/components'
import { useNotification } from '@shared/hooks'
import { toArrayFieldErrors } from '@shared/utils'

import { DeleteLocationUserModal } from '../delete-location-user-modal/DeleteLocationUserModal'

interface LocationAccessProps {
  user: User
  isOnModal?: boolean
  guidingId?: string
}

export const LocationAccess: FC<LocationAccessProps> = ({
  user,
  isOnModal = false,
  guidingId,
}) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const [selectedLocation, setSelectedLocation] = useState<LocationInfo | null>(
    null
  )
  const [selectedLocationToDelete, setSelectedLocationToDelete] =
    useState<LocationInfo | null>(null)
  const [locations, setLocations] = useState<LocationInfo[]>([])
  const [locationUsers, setLocationUsers] = useState<LocationUser[]>([])

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  const getLocationLabel = (location: LocationInfo) => location.name

  const getUserLocations = async () => {
    setIsLoadingPage(true)
    const locationUsers = await api.service('location-users').find({
      query: {
        filter: {
          user_id: user.id,
        },
        page: {
          size: 50,
        },
      },
    })

    setLocationUsers(locationUsers)
    setLocations(
      locationUsers.map((locUser) => {
        return locUser.location
      })
    )
    setIsLoadingPage(false)
  }

  const getLocations = async (options: GetItemsOptions) => {
    setIsLoading(true)
    let searchedLocations = await api.service('location-searches').find({
      query: {
        keyword: options.search,
      },
    })

    searchedLocations = searchedLocations.filter(
      (location: LocationInfo) =>
        !locations.some((loc) => loc.id === location.id)
    )

    setIsLoading(false)
    return searchedLocations || []
  }

  const onButtonClick = async () => {
    try {
      setIsSubmitting(true)
      const locationUser = await api.service('location-users').create({
        user_id: user.id,
        location_id: selectedLocation.id,
      })

      setLocationUsers([...locationUsers, locationUser])
      setLocations([...locations, selectedLocation])

      setSelectedLocation(null)
      setIsButtonEnabled(false)
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      const messages = errors.map((error) => error.message)

      setNotification({
        type: 'error',
        label: messages.join(' | '),
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const onDeleteTag = useCallback(async () => {
    try {
      const locationUser = locationUsers.find(
        (locUser) => locUser.location_id === selectedLocationToDelete.id
      )
      await api.service('location-users').remove(locationUser.id)
      setLocations(
        locations.filter((loc) => loc.id !== selectedLocationToDelete.id)
      )
    } catch (error) {
      setNotification({
        type: 'error',
        label: error.message,
      })
    } finally {
      setSelectedLocationToDelete(null)
      setIsDeleteModalOpen(false)
    }
  }, [selectedLocationToDelete, locations, locationUsers])

  const onChangeValue = (location: LocationInfo) => {
    if (location) {
      setIsButtonEnabled(true)
    }
    setSelectedLocation(location)
  }

  useEffect(() => {
    getUserLocations()
  }, [])

  return (
    <>
      <DeleteLocationUserModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onDelete={onDeleteTag}
        guidingId={guidingId}
      />

      <FieldGroupContainer
        title={!isOnModal && t('user-management.location-access')}
        titleTestID="location-access-title"
      >
        {isLoadingPage ? (
          <Loading
            style={{
              maxHeight: '100px',
            }}
          />
        ) : (
          <ListTypeahead<LocationInfo>
            buttonLabel={t('user-management.add-location')}
            buttonGuidingId={`${guidingId}-add-location`}
            onButtonClick={onButtonClick}
            isButtonLoading={isSubmitting}
            isButtonEnabled={isButtonEnabled}
            typeaheadProps={{
              value: selectedLocation,
              matchItemAndValueProperty: 'id',
              getItemLabel: getLocationLabel,
              getItems: getLocations,
              onChangeValue: onChangeValue,
              loading: isLoading,
              debounceMs: 300,
              label: t('common.location'),
              noOptionsText: t('common.search-for-location'),
              testId: 'location-selector-input',
              autoCompleteTestId: 'location-selector-autocomplete',
              guidingId: `${guidingId}-locationselector`,
            }}
            tagsItems={locations}
            tagsNameSelector={getLocationLabel}
            onDeleteTag={(location: LocationInfo) => {
              setIsDeleteModalOpen(true)
              setSelectedLocationToDelete(location)
            }}
          />
        )}
      </FieldGroupContainer>
    </>
  )
}
