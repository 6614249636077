import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { api, User, LocationUser } from '@shared/api'
import { FieldGroupContainer, Loading, TagList } from '@shared/components'
import { useAuthorization } from '@shared/hooks'
import { checkAllPermissions, checkSomePermissions } from '@shared/utils'

import { EditLocationAccessModal } from '@/components/edit-location-access-modal/EditLocationAccessModal'

export function LocationAccess({ user }: { user: User }) {
  const { t } = useTranslation()
  const { userPermissionSet } = useAuthorization()

  const [locationUsers, setLocationUsers] = useState<LocationUser[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isEditLocationAccessModalOpen, setIsEditLocationAccessModalOpen] =
    useState(false)

  const getLocationUsers = async () => {
    try {
      setIsLoading(true)

      const locationUsers = await api.service('location-users').find({
        query: {
          filter: {
            user_id: user.id,
          },
          page: {
            size: 50,
          },
        },
      })

      setLocationUsers(locationUsers)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getLocationUsers()
  }, [user])

  const isEditButtonVisible =
    checkAllPermissions(
      userPermissionSet,
      'v2.locationusers.get',
      'v2.locations.get'
    ) &&
    checkSomePermissions(
      userPermissionSet,
      'v2.locationusers.post',
      'v2.locationusers.delete'
    )

  return (
    <>
      <EditLocationAccessModal
        user={user}
        isOpen={isEditLocationAccessModalOpen}
        onClose={() => {
          setIsEditLocationAccessModalOpen(false)
          getLocationUsers()
        }}
        guidingId="usermanagement-view"
      />

      <FieldGroupContainer
        title={t('user-management.location-access')}
        buttonData={
          isEditButtonVisible
            ? {
                label: t('user-management.edit-location-access'),
                onClick: () => setIsEditLocationAccessModalOpen(true),
                color: 'secondary',
                testId: 'edit-location-access-button',
                guidingId: 'usermanagement-view-editlocationaccess',
              }
            : undefined
        }
      >
        {isLoading ? (
          <Loading
            style={{
              maxHeight: '100px',
            }}
          />
        ) : (
          <TagList
            items={locationUsers}
            nameSelector={(locationUser) => locationUser.location?.name}
          />
        )}
      </FieldGroupContainer>
    </>
  )
}
